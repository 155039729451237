import * as React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import renderRoute from './routes';
import {
  HAS_NO_ACCESS_TO_DELIVERIES,
  HAS_NO_ACCESS_TO_DOCUMENTS,
  HAS_NO_ACCESS_TO_MERIT_INTEGRATION,
  HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
  HAS_NO_ACCESS_TO_PRODUCTION_DEMAND,
  HAS_NO_ACCESS_TO_PRODUCTS,
  HAS_NO_ACCESS_TO_SETTINGS,
  HAS_NO_ACCESS_TO_SPARE_PARTS,
  HAS_NO_ACCESS_TO_USERS_AND_ACCESSES,
  HAS_NO_ACCESS_TO_WARRANTY_CASES,
  HAS_NO_ACCESS_TO_ORDERS
} from 'services/permission';

const Login = React.lazy(() => import('views/Login'));
const Portal = React.lazy(() => import('views/Portal'));

const Products = React.lazy(() => import('views/Products'));
const ProductDetail = React.lazy(() => import('views/ProductDetail'));
const DeliveryProductDetail = React.lazy(() => import('views/DeliveryProductDetail'));
const ProductionDemand = React.lazy(() => import('views/ProductionDemand'));

const PriceAgreements = React.lazy(() => import('views/PriceAgreements'));
const PriceAgreementsDetail = React.lazy(() => import('views/PriceAgreementsDetail'));
const ArchivedPriceAgreements = React.lazy(() => import('views/ArchivedPriceAgreement'));

const DeliveryRegister = React.lazy(() => import('views/DeliveryRegister'));
const DeliveryInvoice = React.lazy(() => import('views/DeliveryInvoice'));
const DeliveryCart = React.lazy(() => import('views/DeliveryCart'));

const OrdersListing = React.lazy(() => import('views/OrdersListing'));
const OrdersDetailCard = React.lazy(() => import('views/OrdersDetail'));

const MeritIntegration = React.lazy(() => import('views/MeritIntegration'));
const MeritIntegrationDetail = React.lazy(() => import('views/MeritIntegrationDetail'));
const Invoice = React.lazy(() => import('views/Invoices'));

const SpareParts = React.lazy(() => import('views/SpareParts'));
const Documents = React.lazy(() => import('views/Documents'));
const WarrantyCases = React.lazy(() => import('views/WarrantyCases'));
const WarrantyCasesDetail = React.lazy(() => import('views/WarrantyCasesDetail'));
const GlobalSettings = React.lazy(() => import('views/GlobalSettings'));

const Users = React.lazy(() => import('views/Users'));
const UserDetail = React.lazy(() => import('views/UserDetail'));
const Workspace = React.lazy(() => import('views/Workspace'));

const NotFound = React.lazy(() => import('views/NotFound'));
const Forbidden = React.lazy(() => import('views/Forbidden'));

const routes = [
  {
    path: '/',
    element: renderRoute({
      children: <Portal />,
      requireAuth: true,
      pageId: 'portal'
    })
  },
  {
    path: '/login',
    element: renderRoute({
      children: <Login />,
      bgColor: '#c9cbd7',
      pageId: 'login'
    })
  },
  {
    path: '/portal',
    element: renderRoute({
      children: <Portal />,
      requireAuth: true,
      pageId: 'portal'
    })
  },
  {
    path: '/products',
    element: renderRoute({
      children: <Products />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRODUCTS,
      pageId: 'products'
    })
  },
  {
    path: '/products/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRODUCTS,
      pageId: 'product_detail'
    })
  },
  {
    path: '/products/:productId/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'products_orders_detail_card'
    })
  },
  {
    path: '/production_demand',
    element: renderRoute({
      children: <ProductionDemand />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRODUCTION_DEMAND,
      pageId: 'production_demand'
    })
  },
  {
    path: '/production_demand/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRODUCTION_DEMAND,
      pageId: 'product_detail_from_production_demand'
    })
  },
  {
    path: '/production_demand/:productId/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'production_demand_orders_detail_card'
    })
  },
  {
    path: '/price_agreements',
    element: renderRoute({
      children: <PriceAgreements />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
      pageId: 'price_agreements'
    })
  },
  {
    path: '/price_agreements/:agreementId',
    element: renderRoute({
      children: <PriceAgreementsDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
      pageId: 'price_agreements_detail'
    })
  },
  {
    path: '/price_agreements/archive',
    element: renderRoute({
      children: <ArchivedPriceAgreements />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
      pageId: 'price_agreements'
    })
  },
  {
    path: '/price_agreements/archive/:agreementId',
    element: renderRoute({
      children: <PriceAgreementsDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
      pageId: 'price_agreements_detail'
    })
  },
  {
    path: '/price_agreements/product/:productId',
    element: renderRoute({
      children: <PriceAgreementsDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRICE_AGREEMENTS,
      pageId: 'price_agreements_detail'
    })
  },
  {
    path: '/deliveries',
    element: renderRoute({
      children: <DeliveryRegister />,
      requireAuth: false,
      forbidden: HAS_NO_ACCESS_TO_DELIVERIES,
      pageId: 'deliveries'
    })
  },
  {
    path: '/old_deliveries/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_DELIVERIES,
      pageId: 'product_detail_from_orders'
    })
  },
  {
    path: '/deliveries/:deliveryRegistersId',
    element: renderRoute({
      children: <DeliveryProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_DELIVERIES,
      pageId: 'product_detail_from_orders'
    })
  },
  {
    path: '/deliveries/:productId/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'deliveries_orders_detail_card'
    })
  },
  {
    path: '/deliveries/:id/invoices/:deliveryId',
    element: renderRoute({
      children: <DeliveryInvoice />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_DELIVERIES,
      pageId: 'delivery_invoice'
    })
  },
  {
    path: '/delivery_cart',
    element: renderRoute({
      children: <DeliveryCart />,
      requireAuth: true,
      pageId: 'delivery_cart'
    })
  },
  {
    path: '/spare_parts',
    element: renderRoute({
      children: <SpareParts />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_SPARE_PARTS,
      pageId: 'spare_parts'
    })
  },
  {
    path: '/spare_parts/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_PRODUCTS,
      pageId: 'spare_parts_product_detail'
    })
  },
  {
    path: '/spare_parts/:productId/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'spare_parts_orders_detail_card'
    })
  },
  {
    path: '/documents',
    element: renderRoute({
      children: <Documents />,
      requireAuth: false,
      forbidden: HAS_NO_ACCESS_TO_DOCUMENTS,
      pageId: 'documents'
    })
  },
  {
    path: '/documents/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_DOCUMENTS,
      pageId: 'product_detail_from_documents'
    })
  },
  {
    path: '/documents/:productId/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'spare_parts_orders_detail_card'
    })
  },
  {
    path: '/merit_integration',
    element: renderRoute({
      children: <MeritIntegration />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_MERIT_INTEGRATION,
      pageId: 'merit_integration'
    })
  },
  {
    path: '/merit_integration/:supplierId',
    element: renderRoute({
      children: <MeritIntegrationDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_MERIT_INTEGRATION,
      pageId: 'merit_integration_detail'
    })
  },
  {
    path: '/invoices',
    element: renderRoute({
      children: <Invoice />,
      requireAuth: true,
      pageId: 'invoices'
    })
  },
  {
    path: '/users_and_accesses/:tab',
    element: renderRoute({
      children: <Users />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_USERS_AND_ACCESSES,
      pageId: 'users_and_accesses'
    })
  },
  {
    path: '/users_and_accesses/:tab/:supplierId/users',
    element: renderRoute({
      children: <Workspace />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_USERS_AND_ACCESSES,
      pageId: 'users_and_accesses'
    })
  },
  {
    path: '/users_and_accesses/:tab/:supplierId/users/:userId',
    element: renderRoute({
      children: <UserDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_USERS_AND_ACCESSES,
      pageId: 'users_and_accesses'
    })
  },
  {
    path: '/warranty_cases',
    element: renderRoute({
      children: <WarrantyCases />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_WARRANTY_CASES,
      pageId: 'warranty_cases'
    })
  },
  {
    path: '/warranty_cases/:warrantyId',
    element: renderRoute({
      children: <WarrantyCasesDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_WARRANTY_CASES,
      pageId: 'warranty_cases_detail'
    })
  },
  {
    path: '/settings',
    element: renderRoute({
      children: <GlobalSettings />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_SETTINGS,
      pageId: 'global_settings'
    })
  },
  {
    path: '/forbidden',
    element: renderRoute({
      children: <Forbidden />,
      requireAuth: false,
      pageId: 'forbidden'
    })
  },
  {
    path: '/*',
    element: renderRoute({
      children: <NotFound />,
      requireAuth: false,
      pageId: 'not_found'
    })
  },
  {
    path: '/orders',
    element: renderRoute({
      children: <OrdersListing />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'orders_listing'
    })
  },
  {
    path: '/orders/:orderId',
    element: renderRoute({
      children: <OrdersDetailCard />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'orders_detail_card'
    })
  },
  {
    path: '/orders/:orderId/product/:productId',
    element: renderRoute({
      children: <ProductDetail />,
      requireAuth: true,
      forbidden: HAS_NO_ACCESS_TO_ORDERS,
      pageId: 'product_detail_from_orders'
    })
  }
];

const router = createBrowserRouter(routes);

export default router;
